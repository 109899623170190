import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import {
  ComponentType,
  CountryModel,
  Currency,
  DictionaryEntryModel,
  DscPromoModel,
  OrderDeliveryPointResponse,
  PaymentType,
  StoreBasicModel,
  StoreCfCodeResponse,
  StoreFullModel,
  StoreResourceService,
  StoreWorkTimeModel,
  UiLabel
} from '../vn-api';
import { AppPropertyBasicModel, FiscalInfoModel, PagedList, StoreMoneyInfoModel } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class HxStoreService {
  constructor(
    private http: HttpClient,
    private storeResourceService: StoreResourceService,
  ) {
  }

  getStores(): Promise<StoreBasicModel[]> {
    return firstValueFrom(this.http.get<StoreBasicModel[]>(`/api/vanilla/stores`));
  }

  getFullStores(params?: {
    page?: number;
    limit?: number;
    includeDisabled?: boolean;
    open?: boolean;
    cityId?: number;
    countryId?: number;
    orgId?: number;
    brandId?: number;
    date?: string;
    sort?: string[];
  }): Promise<PagedList<StoreFullModel>> {
    return firstValueFrom(this.http.get<PagedList<StoreFullModel>>(`/api/vanilla/stores/full`, {params: toHttpParams(params, true)}));
  }

  getStoreById(storeId: number): Promise<StoreBasicModel> {
    return firstValueFrom(this.http.get<StoreFullModel>(`/api/vanilla/stores/${storeId}`));
  }

  getFullStore(storeId: number): Promise<StoreFullModel> {
    return firstValueFrom(this.http.get<StoreFullModel>(`/api/vanilla/stores/${storeId}/full`));
  }

  getStoreCodeById(storeId: number): Observable<{ code: string, time: string }> {
    return this.http.get<{ code: string, time: string }>(`/api/vanilla/stores/${storeId}/code`);
  }

  getFiscalInfoByStoreId(storeId: number): Observable<FiscalInfoModel> {
    return this.http.get<FiscalInfoModel>(`/api/vanilla/stores/${storeId}/fiscal-info`);
  }

  getCountryByStoreId(storeId: number): Promise<CountryModel> {
    return firstValueFrom(this.http.get<CountryModel>(`/api/vanilla/stores/${storeId}/country`));
  }

  getCountries(): Observable<CountryModel[]> {
    return this.http.get<CountryModel[]>(`/api/vanilla/countries`);
  }

  searchStore(term?: string): Observable<StoreBasicModel[]> {
    if (term) {
      return this.http.get<StoreBasicModel[]>(`/api/vanilla/stores?q=${term}`);
    } else {
      return this.http.get<StoreBasicModel[]>(`/api/vanilla/stores`);
    }
  }

  getStoreByIds(storeIds: number[]): Promise<StoreBasicModel[]> {
    return firstValueFrom(this.http.get<StoreBasicModel[]>(`/api/vanilla/stores?ids=${storeIds.join(',')}`));
  }

  getActivePaymentTypes(storeId: number): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(`/api/vanilla/stores/${storeId}/payment-types`);
  }

  getStoresByCity(cityId: number, params?: { date?: string, brandId?: number, orgId?: number, open?: boolean }): Promise<PagedList<StoreFullModel>> {
    return this.getFullStores({...params, ...{cityId: cityId, limit: 1000}});
  }

  saveStore(req: SaveStoreRequest): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(`/api/vanilla/stores`, req);
  }

  removeStore(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/stores/${id}`);
  }

  getPropertiesById(storeId: number): Observable<AppPropertyBasicModel[]> {
    return this.http.get<AppPropertyBasicModel[]>(`/api/vanilla/stores/${storeId}/properties`);
  }

  getPropertyByKey(storeId: number, key: string): Observable<AppPropertyBasicModel | undefined> {
    return this.http.get<AppPropertyBasicModel>(`/api/vanilla/stores/${storeId}/properties?key=${key}`);
  }

  getPublicPropertyByKey(storeId: number, key: string): Observable<AppPropertyBasicModel | undefined> {
    return this.http.get<AppPropertyBasicModel>(`/api/vanilla/stores/${storeId}/properties/key/${key}`);
  }

  saveProperty(storeId: number, req: { enabled: boolean; key: string; value: string; }): Promise<{ id: number }> {
    return firstValueFrom(this.http.post<{ id: number }>(`/api/vanilla/stores/${storeId}/properties`, req));
  }

  removeProperty(storeId: number, id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/stores/${storeId}/properties/${id}`);
  }

  getRetailOutlets(storeId: number): Promise<StoreBasicModel[]> {
    return firstValueFrom(this.http.get<StoreBasicModel[]>(`/api/vanilla/stores/${storeId}/retail-outlets`));
  }

  getSettings(storeId: number): Promise<StoreSettings> {
    return firstValueFrom(this.http.get<StoreSettings>(`/api/vanilla/stores/${storeId}/settings`));
  }

  saveSettings(storeId: number, settings: StoreSettings): Observable<void> {
    return this.http.post<void>(`/api/vanilla/stores/${storeId}/settings`, settings);
  }

  clientCall(body: { clientPhone: string, operatorId?: string, sipNumber?: string }): Observable<void> {
    return this.http.post<void>(`/api/vanilla/stores/my/client-call`, body);
  }

  getReceiptLogoUrl(storeId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`/api/vanilla/stores/${storeId}/receipt/logo`);
  }

  getReferrersByStoreId(id: number): Promise<DictionaryEntryModel[]> {
    return firstValueFrom(this.http.get<DictionaryEntryModel[]>(`/api/vanilla/stores/${id}/referrers`));
  }

  getAvailableForPaymentApprovals(): Observable<StoreBasicModel[]> {
    return this.http.get<StoreBasicModel[]>(`/api/vanilla/stores/available-for-payment-approvals`);
  }

  getOwnerAllStores(storeId: number): Observable<StoreBasicModel[]> {
    return this.http.get<StoreBasicModel[]>(`/api/vanilla/stores/owner?store_id=${storeId}`);
  }

  getStoreMoneyInfo(id: number): Promise<StoreMoneyInfoModel> {
    return firstValueFrom(this.http.get<StoreMoneyInfoModel>(`/api/vanilla/stores/${id}/money-info`));
  }

  getStoreCfCode(storeId: number): Promise<StoreCfCodeResponse> {
    return firstValueFrom(this.storeResourceService.getStoreCfCode(storeId));
  }

  getDeliveryOrderPoints(storeId: number, fromDate: string, toDate: string): Promise<OrderDeliveryPointResponse> {
    return firstValueFrom(this.storeResourceService.getDeliveryOrderPoints(storeId, fromDate, toDate));
  }

  getStoreEmployeePromos(storeId: number): Promise<DscPromoModel[]> {
    return firstValueFrom(this.storeResourceService.getStoreEmployeePromos(storeId));
  }

  getAllFranchiseStores(storeId: number): Promise<StoreBasicModel[]> {
    return firstValueFrom(this.storeResourceService.getAllFranchiseStores(storeId));
  }

  getAllStoresByCurrentUserAndComponentType(componentType: ComponentType): Promise<StoreBasicModel[]> {
    return firstValueFrom(this.storeResourceService.getAllStoresByCurrentUserAndComponentType(componentType));
  }
}

export interface StoreSettings {
  onlineBeforeCloseLimit?: number;
  ext_1C_code?: string;
  signUserId?: number;
  switchDate?: string;
  referrers?: string[];
}

export interface SaveStoreRequest {
  id?: number;
  enabled: boolean;
  organizationId: number;
  countryId: number;
  cityId: number;
  address: string;
  longitude: number;
  latitude: number;
  timezone: string;
  title: UiLabel;
  hasOnlineSell: boolean;
  hasDelivery: boolean;
  hasOnlineDelivery: boolean;
  hasDecoration: boolean;
  hasInscription: boolean;
  startDate: string;
  endDate?: string;
  currency: Currency;
  callCenterPhones?: string[];
  workTimes: Partial<StoreWorkTimeModel>[];
  retailOutletIds?: number[];
  brandId: number;
  slug: string;
}
