import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedList } from '../interface';
import { toHttpParams } from '../utils/globals';
import { map } from 'rxjs/operators';
import { SortType, UnitOfMeasureModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxUnitOfMeasureService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getUnitOfMeasures(params?: {
    page?: number,
    limit?: number,
    orderBy?: string,
    sort?: SortType,
    query?: string,
    ids?: number[],
    includeDisabled?: boolean,
  }): Observable<PagedList<UnitOfMeasureModel>> {
    return this.http.get<PagedList<UnitOfMeasureModel>>(`/api/vanilla/unit-of-measures`, {params: toHttpParams(params, true)});
  }

  getUnitOfMeasuresByIds(ids: number[]): Observable<UnitOfMeasureModel[]> {
    return this.getUnitOfMeasures({ids: ids}).pipe(map(res => res.list));
  }
}
