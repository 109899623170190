import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HxTagService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getTags(containerClass: string): Promise<string[]> {
    return firstValueFrom(this.httpClient.get<string[]>(`/api/vanilla/tags/container-class/${containerClass}`));
  }

  saveTags(containerClass: string, tags: string[]): Promise<string[]> {
    return firstValueFrom(this.httpClient.post<string[]>(`/api/vanilla/tags/container-class/${containerClass}`, {tags: tags}));
  }
}
