import { Pipe, PipeTransform } from '@angular/core';

import { UiLabel as VnLabel, UiLabel } from '../vn-api';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'uiLabel'
})
export class HxUiLabelPipe implements PipeTransform {

  constructor(private tr: TranslocoService) {
  }

  transform(value?: UiLabel | VnLabel, showAll = false): string {
    if (!value) {
      return '';
    }
    if (showAll) {
      return Object.entries(value)
        .filter(([k, v]) => !!v)
        .map(([v, k]) => `${k} (${v})`)
        .join(', ');
    }
    if (this.tr.getActiveLang() === 'kk') {
      return value.kk ?? '';
    } else if (this.tr.getActiveLang() === 'en') {
      return value.en ?? '';
    }
    return value.ru ?? '';
  }
}
