import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { HxProductInfoSelectComponent } from './product/info-select/product-info-select.component';
import { HxStoreSelectComponent } from './store-select/store-select.component';
import { HxTagSelectComponent } from './tag-select/tag-select.component';
import { HxUserSelectComponent } from './user-select/user-select.component';
import { HxOrgSelectComponent } from './org-select/org-select.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { HxCitySelectComponent } from './city-select/city-select.component';
import { HxCountrySelectComponent } from './country-select/country-select.component';
import { HxPipeModule } from 'hx-services';
import { HxLabelEditComponent } from './label-edit/label-edit.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { HxBrandSelectComponent } from './brand-select/brand-select.component';
import { HxProductCategorySelectComponent } from './product/category-select/product-category-select.component';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { MarkdownModule } from 'ngx-markdown';
import { HxEnumSelectComponent } from './enum-select/enum-select.component';
import { HxBankSelectComponent } from './bank-select/bank-select.component';
import { kk } from './i18n/kk';
import { ru } from './i18n/ru';
import { HxUnitMeasureSelectComponent } from './unit-measure-select/unit-measure-select.component';
import { HxSpendCategorySelectComponent } from './spend-category-select/spend-category-select.component';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { HxGroupSelectComponent } from './group-select/group-select.component';
import { HxWorkflowCategorySelectComponent } from './workflow-category-select/workflow-category-select.component';

export * from './product/info-select/product-info-select.component';
export * from './store-select/store-select.component';
export * from './tag-select/tag-select.component';
export * from './paged-list/paged-list.component';
export * from './user-select/user-select.component';
export * from './org-select/org-select.component';
export * from './city-select/city-select.component';
export * from './country-select/country-select.component';
export * from './label-edit/label-edit.component';
export * from './brand-select/brand-select.component';
export * from './product/category-select/product-category-select.component';
export * from './enum-select/enum-select.component';
export * from './unit-measure-select/unit-measure-select.component';
export * from './spend-category-select/spend-category-select.component';
export * from './bank-select/bank-select.component';
export * from './group-select/group-select.component';
export * from './workflow-category-select/workflow-category-select.component';

const components = [
  HxProductInfoSelectComponent,
  HxStoreSelectComponent,
  HxTagSelectComponent,
  HxUserSelectComponent,
  HxOrgSelectComponent,
  HxCitySelectComponent,
  HxCountrySelectComponent,
  HxLabelEditComponent,
  HxBrandSelectComponent,
  HxProductCategorySelectComponent,
  HxEnumSelectComponent,
  HxUnitMeasureSelectComponent,
  HxSpendCategorySelectComponent,
  HxBankSelectComponent,
  HxGroupSelectComponent,
  HxWorkflowCategorySelectComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    NzSelectModule,
    NzTagModule,
    NzIconModule,
    NzFormModule,
    NzButtonModule,
    NzInputModule,
    NzDrawerModule,
    NzTypographyModule,
    MarkdownModule,
    HxPipeModule,
    NzTreeSelectModule,
  ],
  exports: components
})
export class HxComponentModule {
  constructor(private translocoService: TranslocoService) {
    this.translocoService.setTranslation(kk, 'kk', {merge: true});
    this.translocoService.setTranslation(ru, 'ru', {merge: true});
  }
}
