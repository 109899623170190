import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { map } from 'rxjs/operators';
import { ClassName, PagedList, ProductCategoryModel, WorkflowCategoryModel } from '../interface';
import { UiLabel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxCategoryService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getWorkflowCategories(params?: {limit?: number; page?: number; query?: string; brandId?: number}): Observable<PagedList<WorkflowCategoryModel>> {
    return this.httpClient.get<PagedList<WorkflowCategoryModel>>(`/api/vanilla/workflows/categories`, { params: toHttpParams(params, true) });
  }

  getWorkflowCategoryById(categoryId: number): Observable<WorkflowCategoryModel> {
    return this.httpClient.get<WorkflowCategoryModel>(`/api/vanilla/workflows/categories/${categoryId}`);
  }

  saveWorkflowCategory(body: WorkflowCategoryModel): Observable<WorkflowCategoryModel> {
    return this.httpClient.post<WorkflowCategoryModel>(`/api/vanilla/workflows/categories`, body);
  }

  deleteWorkflowCategory(categoryId: number): Observable<WorkflowCategoryModel> {
    return this.httpClient.delete<WorkflowCategoryModel>(`/api/vanilla/workflows/categories/${categoryId}`);
  }

  getCategories(params?: {
    query?: string;
    brandId?: number;
    page?: number;
    limit?: number;
    includeDisabled?: boolean;
    classNames?: ClassName[];
  }): Observable<PagedList<ProductCategoryModel>> {
    return this.httpClient.get<PagedList<ProductCategoryModel>>(`/api/vanilla/categories`, {params: toHttpParams(params, true)});
  }

  getCategoriesByIds(ids: number[]): Observable<ProductCategoryModel[]> {
    return this.httpClient.get<PagedList<ProductCategoryModel>>(`/api/vanilla/categories`, {params: toHttpParams({ids: ids}, true)})
      .pipe(map(res => res.list));
  }

  getCategory(categoryId: number): Observable<ProductCategoryModel> {
    return this.httpClient.get<ProductCategoryModel>(`/api/vanilla/categories/${categoryId}`);
  }

  saveCategory(req: ProductCategoryModel): Observable<{ id: number }> {
    return this.httpClient.post<{ id: number }>(`/api/vanilla/categories`, req);
  }

  removeCategory(categoryId: number): Observable<any> {
    return this.httpClient.delete(`/api/vanilla/categories/${categoryId}`);
  }
}

export interface WorkflowCategoryRequest {
  title: UiLabel;
  displayOrder: number;
}
