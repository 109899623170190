/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ComponentType = 'delivery' | 'cb' | 'cf' | 'manager' | 'cc' | 'kiosk' | 'adm' | 'profile';

export const ComponentType = {
    delivery: 'delivery' as ComponentType,
    cb: 'cb' as ComponentType,
    cf: 'cf' as ComponentType,
    manager: 'manager' as ComponentType,
    cc: 'cc' as ComponentType,
    kiosk: 'kiosk' as ComponentType,
    adm: 'adm' as ComponentType,
    profile: 'profile' as ComponentType
};

