// Common enums

export enum NotificationType {
  INFO = 'INFO',
  ALERT = 'ALERT',
}

export enum ConfirmStatus {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}

export enum FiscalProvider {
  WEBKASSA = 'WEBKASSA',
  PLATFORMA_OFD = 'PLATFORMA_OFD',
}

export enum PriceType {
  DEFAULT = 'DEFAULT', CUSTOM = 'CUSTOM'
}

export enum DocsType {
  ID_CARD = 'ID_CARD',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  MEDICAL_CARD = 'MEDICAL_CARD'
}

export enum InvoiceType {
  PURCHASE = 'PURCHASE',
  DECOMMISSION = 'DECOMMISSION',
  INVENTORY = 'INVENTORY',
  CONSUMPTION = 'CONSUMPTION',
  PRODUCTION = 'PRODUCTION',
  TRANSFER = 'TRANSFER'
}

export enum InvoiceStatus {
  NEW = 'NEW',
  SENT = 'SENT',
  SHIPMENT = 'SHIPMENT',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  DISCARDED = 'DISCARDED',
  INVENTORY = 'INVENTORY'
}

export enum StorageInvoiceStatus {
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
}

export enum FeedbackType {
  CALLCENTER = 'CALLCENTER',
  DELIVERY = 'DELIVERY',
  SITE = 'SITE',
  PRODUCT = 'PRODUCT',
  SUPPORT = 'SUPPORT',
  CASHBOX = 'CASHBOX',
  DECOR = 'DECOR',
}

export enum CompositeProductType {
  SINGLE = 'SINGLE',
  COMBO = 'COMBO',
}

export enum ProductCategoryType {
  DECORATION = 'DECORATION', // оформление
  SERVICE = 'SERVICE',    // услуги
  FAVOURITE = 'FAVOURITE',	// избранные
  STANDART = 'STANDART',   // стандартные
  NEW_FORMAT = 'NEW_FORMAT',  // нового формата
}

export enum OrderStatus {
  CART = 'CART',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum RecipeType {
  INPUT_PRODUCT = 'INPUT_PRODUCT',
  OUTPUT_PRODUCT = 'OUTPUT_PRODUCT',
}

export enum Folder {
  DISCARD = 'DISCARD',
  DECOR = 'DECOR',
  LETTER_OF_AUTHORITY = 'LETTER_OF_AUTHORITY',
  PAYMENT_ORDER = 'PAYMENT_ORDER',
  PURCHASE = 'PURCHASE',
  CONTRACT = 'CONTRACT',
  SYSTEM = 'SYSTEM',
}

export enum SysFilename {
  stamp = 'stamp',
  avatar = 'avatar',
  sign = 'sign',
  letterOfAuthority = 'letterOfAuthority',
}

export enum TableRef {
  hc_profiles = 'hc_profiles',
  hc_organizations = 'hc_organizations',
  hc_orders = 'hc_orders',
}

export enum ViewType {
  SPLIT = 'SPLIT',
  MERGED = 'MERGED'
}

// @ATTENTION если добавляешь сюда запись - ОБЯЗАТЕЛЬНО добавь и в PaymentHolder и в PAYMENT_KEY_MAP

export enum BonusResultType {
  FIXED_PRICE = 'FIXED_PRICE',
  MONEY_DISCOUNT = 'MONEY_DISCOUNT',
  PERCENT_DISCOUNT = 'PERCENT_DISCOUNT',
}
export enum GisType {
  dgis = 'dgis',
  yandex = 'yandex',
  google = 'google',
}
export enum PaymentApprovalStatus {
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  SUCCEED = 'SUCCEED',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  MODIFIED = 'MODIFIED',
}

export enum DictionaryKey {
  utmReferrer = 'utm_referrer',
  spendType = 'spend_type',
}

export enum TimeInterval {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export enum Shape {
  Circle = 'Circle',
  Roll = 'Roll',
  Square = 'Square',
}

export enum EmployeeType {
  EMPLOYEE = 'EMPLOYEE',
  TEMP = 'TEMP'
}

export enum AccrualType {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY'
}

export enum AccountType {
  CASH = 'CASH',
  BANK_ACCOUNT = 'BANK_ACCOUNT'
}

export enum EncashmentOperationType {
  PURCHASE = 'PURCHASE', // Закуп
  COLLECTION = 'COLLECTION', // Инкассация
  WITHDRAWAL = 'WITHDRAWAL', // Снятие
  ADJUSTMENT = 'ADJUSTMENT' // Корректировка
}
