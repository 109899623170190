import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ClassName, CompositeProductInfoModel, CompositeProductModel, PagedList, ProductInfoModel, SaleCityProductModel, } from '../interface';
import { map } from 'rxjs/operators';
import { toHttpParams } from '../utils/globals';
import { KindType, ProductInfoType, SaleProductModel, SortType } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxProductInfoService {
  constructor(
    private http: HttpClient,
  ) {
  }

  saveProductInfo(model: ProductInfoModel) {
    return this.http.post<{ id: number }>(`/api/vanilla/product-infos`, model);
  }

  getProductInfos(params?: {
    page?: number,
    limit?: number,
    orderBy?: string,
    sort?: SortType,
    types?: ProductInfoType[],
    searchText?: string,
    ids?: number[],
    globalForSale?: boolean,
    cfOutputProduct?: boolean,
    toCbInvoice?: boolean,
    kinds?: KindType[],
    categoryIds?: number[],
    classNames?: ClassName[],
    includeDisabled?: boolean,
    brandIds?: number[],
    storeId?: number,
    purchasable?: boolean
  }): Observable<PagedList<ProductInfoModel>> {
    return this.http.get<PagedList<ProductInfoModel>>(`/api/vanilla/product-infos`, {params: toHttpParams(params, true)});
  }

  getProductInfoByIds(ids: number[]): Observable<ProductInfoModel[]> {
    if (ids.length === 0) {
      return of([]);
    }
    return this.getProductInfos({ids: ids})
      .pipe(map(paged => paged.list));
  }

  getProductInfo(id: number): Observable<ProductInfoModel> {
    return this.http.get<ProductInfoModel>(`/api/vanilla/product-infos/${id}`);
  }

  removeProductInfo(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/product-infos/${id}`);
  }

  getCompositeProductById(id: number): Observable<CompositeProductModel> {
    return this.http.get<CompositeProductModel>(`/api/vanilla/product-infos/composite-products/${id}`);
  }

  getCompositeProducts(params?: {
    page?: number,
    limit?: number,
    language?: string,
    sort?: string[],
    countryIds?: number[],
    storesOp?: string,
    tags?: string[],
    tagsOp?: string,
  }): Observable<PagedList<CompositeProductInfoModel>> {
    return this.http.get<PagedList<CompositeProductInfoModel>>(`/api/vanilla/product-infos/composite-products`,
      {params: toHttpParams(params, true)});
  }

  getProductInfosForSale(param: { storeId: number; categoryIds: number[]; fromDate: string; }): Observable<SaleProductModel[]> {
    return this.http.get<SaleProductModel[]>('/api/vanilla/product-infos/sale', {params: toHttpParams(param, true)});
  }

  getProductInfosByCityId(param: {
    cityId: number;
    brandId: number;
    categoryIds: number[];
    fromDate: string;
    type?: ProductInfoType;
  }): Promise<SaleCityProductModel[]> {
    return firstValueFrom(this.http.get<SaleCityProductModel[]>(`/api/vanilla/product-infos/cities/${param.cityId}/sale`, {params: toHttpParams(param, true)}));
  }

  saveCompositeProduct(model: CompositeProductModel): Observable<{ id: number }> {
    if (model.id) {
      return this.http.put<{ id: number }>(`/api/vanilla/product-infos/composite-products/${model.id}`, model);
    } else {
      return this.http.post<{ id: number }>(`/api/vanilla/product-infos/composite-products`, model);
    }
  }

  removeCompositeProduct(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/product-infos/composite-products/${id}`);
  }
}
