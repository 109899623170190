import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { BankModel, PagedList } from '../interface';
import { map } from 'rxjs/operators';

export interface BankRequest {
  id: number;
  name: string;
  address: string;
  oldBic: string;
  bic: string;
}

@Injectable({
  providedIn: 'root'
})
export class HxBankService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getBankList(params?: {page?: number; limit?: number; query?: string; ids?: number[]}): Observable<PagedList<BankModel>> {
    return this.http.get<PagedList<BankModel>>(`/api/vanilla/banks`, { params: toHttpParams(params, true) });
  }

  getBankById(id: number): Observable<BankModel> {
    return this.http.get<BankModel>(`/api/vanilla/banks/${id}`);
  }

  saveBank(body: BankRequest): Observable<BankModel> {
    return this.http.post<BankModel>(`/api/vanilla/banks`, body);
  }

  deleteBank(bankId: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/banks/${bankId}`);
  }

  getBankByIds(ids: number[]): Observable<BankModel[]> {
    if (ids.length === 0) {
      return of([]);
    }
    return this.getBankList({ids: ids})
      .pipe(map(paged => paged.list));
  }
}
