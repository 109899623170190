import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupModel, PagedList } from '../interface';
import { toHttpParams } from '../utils/globals';

@Injectable({
  providedIn: 'root'
})
export class HxGroupService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getGroups(params?: {
    page?: number;
    limit?: number;
    includeDisabled?: boolean;
    query?: string;
    ids?: number[];
  }): Observable<PagedList<GroupModel>> {
    return this.http.get<PagedList<GroupModel>>(`/api/vanilla/groups`, {params: toHttpParams(params, true)});
  }

  getGroupById(groupId: number): Observable<GroupModel> {
    return this.http.get<GroupModel>(`/api/vanilla/groups/${groupId}`);
  }
}
