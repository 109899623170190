import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HxPipeModule } from 'hx-services';
import { NzModule } from './nz.module';
import { HxComponentModule } from 'hx-components';
import { MarkdownModule } from 'ngx-markdown';

const modules: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  HxPipeModule,
  NzModule,
  MarkdownModule.forRoot({loader: HttpClient}),
  HxComponentModule,
];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules,
})
export class SharedModule {
}
//
