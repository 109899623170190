import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { Params } from '@angular/router';
import { DateRange, LocalDate, PagedList, SpendCategoryFullModel, SpendCategoryModel, SpendingFullModel, SpendingModel } from '../interface';
import { toHttpParams } from '../utils/globals';
import { UiLabel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxSpendService {

  constructor(
    private http: HttpClient,
  ) {
  }

  // spend category
  getSpendCategoryList(params?: {
    page?: number;
    limit?: number;
    query?: string;
    ids?: number[];
    brandId?: number;
    includeDisabled?: boolean;
    root?: boolean;
  }) {
    return firstValueFrom(this.http.get<PagedList<SpendCategoryFullModel>>(`/api/vanilla/adm/spend/categories`, {params: toHttpParams(params, true)}));
  }

  getSpendCategoryById(categoryId: number) {
    return firstValueFrom(this.http.get<SpendCategoryModel>(`/api/vanilla/adm/spend/categories/${categoryId}`));
  }

  createSpendCategory(body: SaveSpendCategoryRequest) {
    return firstValueFrom(this.http.post<SpendCategoryModel>(`/api/vanilla/adm/spend/categories`, body));
  }

  removeSpendCategoryById(categoryId: number) {
    return firstValueFrom(this.http.delete<void>(`/api/vanilla/adm/spend/categories/${categoryId}`));
  }

  getSpendStoreList(params: SpendingRequest) {
    return firstValueFrom(this.http.get<PagedList<SpendingFullModel>>(`/api/vanilla/adm/spend/store/infos`, {params: toHttpParams(params, true)}));
  }

  getSpendById(spendId: number) {
    return firstValueFrom(this.http.get<SpendingFullModel>(`/api/vanilla/adm/spend/infos/${spendId}`));
  }

  saveSpendStore(body: SaveStoreSpendingRequest) {
    return firstValueFrom(this.http.post<SpendingModel>(`/api/vanilla/adm/spend/store/infos`, body));
  }

  getSpendNames(params: Params): Observable<any> {
    return this.http.get(`/api/vanilla/adm/spend/names`, {params: params});
  }

  getSpendName(nameId: number): Observable<any> {
    return this.http.get(`/api/vanilla/adm/spend/names/${nameId}`);
  }

  spendPaid(spendId: number, body: any): Observable<any> {
    return this.http.post(`/api/vanilla/adm/spend/infos/${spendId}/paid`, body);
  }

  spendPaidCancelled(spendId: number): Observable<any> {
    return this.http.delete(`/api/vanilla/adm/spend/infos/${spendId}/paid`);
  }

  remove(spendId: number): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`/api/vanilla/adm/spend/infos/${spendId}`));
  }
}

interface SaveSpendCategoryRequest {
  id?: number;
  enabled: boolean;
  name: UiLabel;
  parentId?: number;
  brandId: number;
}

interface SpendingRequest {
  storeIds?: number[];
  date?: DateRange;
  organizationId?: number;
  spendCategoryId?: number;
  page?: number;
  limit?: number;
  sort?: string;
  orderBy?: string;
  incomingNumber?: string;
  paidDate?: string;
}

interface SaveStoreSpendingRequest {
  id?: number;
  spendCategoryId: number;
  storeId: number;
  total: number;
  date: LocalDate;
  incomingNumber?: string;
}
