import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { ObjectUploadResponse, UiLabel } from '../vn-api';
import { BrandModel, BrandTitleModel, PagedList } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class HxBrandService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getBrands(params?: {
    page?: number,
    limit?: number,
    query?: string
  }): Observable<PagedList<BrandModel>> {
    return this.http.get<PagedList<BrandModel>>(`/api/vanilla/brands`, {params: toHttpParams(params, true)});
  }

  getBrandById(id: number): Observable<BrandModel> {
    return this.http.get<BrandModel>(`/api/vanilla/brands/${id}`);
  }

  getMyBrands(): Observable<BrandModel[]> {
    return this.http.get<BrandModel[]>(`/api/vanilla/brands/my`);
  }

  removeBrand(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/brands/${id}`);
  }

  saveBrand(req: {
    id?: number;
    title?: string;
  }): Observable<BrandModel> {
    return this.http.post<BrandModel>(`/api/vanilla/brands`, req);
  }

  getBrandTitles(params?: {
    brandId?: number;
    page?: number;
    limit?: number;
  }): Observable<PagedList<BrandTitleModel>> {
    return this.http.get<PagedList<BrandTitleModel>>(`/api/vanilla/brands/titles`, {params: toHttpParams(params, true)});
  }

  getBrandTitleById(id: number): Observable<BrandTitleModel> {
    return this.http.get<BrandTitleModel>(`/api/vanilla/brands/titles/${id}`);
  }

  removeBrandTitle(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/brands/titles/${id}`);
  }

  saveBrandTitle(req: {
    id?: number;
    title?: UiLabel;
    brandId?: number;
    countryId?: number;
  }): Observable<BrandTitleModel> {
    return this.http.post<BrandTitleModel>(`/api/vanilla/brands/titles`, req);
  }

  getFileUploadUrl(id: number): string {
    return `/api/vanilla/brands/titles/${id}/files`;
  }

  removeFile(id: number, fileName: string): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/brands/titles/${id}/files`, {params: toHttpParams({fileName: fileName}, true)});
  }

  getBrandImageUrls(id: number): Observable<ObjectUploadResponse[]> {
    return this.http.get<ObjectUploadResponse[]>(`/api/vanilla/brands/titles/${id}/files`);
  }
}
