import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { PagedList, UserComponentModel } from '../interface';
import { toHttpParams } from '../utils/globals';
import {
  ComponentType,
  CountryModel,
  EmployeeDiscountResponse,
  Gender,
  MaritalStatus,
  SaveCallCenterUserRequest,
  SaveEmployeeDiscountRequest,
  UserBasicModel,
  UserProfileModel,
  UserResourceService
} from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxUserService {
  constructor(
    private httpClient: HttpClient,
    private userResourceService: UserResourceService,
  ) { }

  getUserById(id: number): Observable<UserBasicModel> {
    return this.httpClient.get<UserBasicModel>(`/api/vanilla/users/${id}`);
  }

  getUsers(params?: {
    limit?: number;
    components?: ComponentType[];
    query?: string;
    storeId?: number;
    includeDisabled?: boolean;
  }): Observable<PagedList<UserBasicModel>> {
    return this.httpClient.get<PagedList<UserBasicModel>>(`/api/vanilla/users`, {params: toHttpParams(params, true)});
  }

  getUserByIds(ids: number[]): Observable<PagedList<UserProfileModel>> {
    return this.httpClient.get<PagedList<UserProfileModel>>(`/api/vanilla/users`, {params: toHttpParams({ids: ids}, true)});
  }

  getUserProfiles(params: {page: number, query: string, storeId: number}): Observable<PagedList<UserProfileModel>> {
    return this.httpClient.get<PagedList<UserProfileModel>>(`/api/vanilla/users/profiles`, { params: toHttpParams(params, true) });
  }

  removeUserFromStore(userId: number, storeId: number): Observable<any> {
    return this.httpClient.delete(`/api/vanilla/users/${userId}/stores/${storeId}/memberships`);
  }

  fireUser(userId: number, storeId: number): Observable<any> {
    return this.httpClient.post(`/api/vanilla/users/${userId}/stores/${storeId}/fire`, {});
  }

  getUserByPhone(phone: string): Observable<UserProfileModel | undefined> {
    return this.httpClient.get<UserProfileModel>(`/api/vanilla/users/phone/${phone}`);
  }

  resetPassword(userId: number): Promise<void> {
    return firstValueFrom(this.httpClient.post<void>(`/api/vanilla/users/${userId}/reset-password`, {}));
  }

  getUser(userId: number): Observable<UserProfileModel> {
    return this.httpClient.get<UserProfileModel>(`/api/vanilla/users/${userId}/full`);
  }

  saveUser(body: UserSaveRequest): Observable<UserProfileModel> {
    return this.httpClient.post<UserProfileModel>(`/api/vanilla/users`, body);
  }

  deleteMembership(membershipId: number): Observable<any> {
    return this.httpClient.delete(`/api/vanilla/users/membership/${membershipId}`);
  }

  setDefaultStore(storeId: number): Observable<any> {
    return this.httpClient.post(`/api/vanilla/identity/update-store/${storeId}`, {}, { responseType: 'text' });
  }

  setOperatorId(asteriskId: number): Observable<UserProfileModel> {
    return this.httpClient.post<UserProfileModel>(`/api/vanilla/users/operators/asterisks/${asteriskId}`, {});
  }

  getOperatorIdList(): Observable<UserBasicModel[]> {
    return this.httpClient.get<UserBasicModel[]>(`/api/vanilla/users/operators/asterisks`);
  }

  getCfUsers(): Observable<PagedList<UserProfileModel>> {
    return this.httpClient.get<PagedList<UserProfileModel>>(`/api/vanilla/cf/users`);
  }

  getManagers(params?: { groupId?: number}): Observable<UserComponentModel[]> {
    return this.httpClient.get<UserComponentModel[]>(`/api/vanilla/users/managers`, {params: toHttpParams(params, true)});
  }

  getEmployees(storeId: number, componentType: string): Observable<UserProfileModel[]> {
    return this.httpClient.get<UserProfileModel[]>(`/api/vanilla/users/stores/${storeId}/components/${componentType}/employees`);
  }

  saveMemberships(userId: number, body: UserMembershipRequest): Promise<UserProfileModel> {
    return firstValueFrom(this.httpClient.post<UserProfileModel>(`/api/vanilla/users/${userId}/memberships`, body));
  }

  getCallCenterUsers(countryId: number, query?: string): Promise<UserProfileModel[]> {
    return firstValueFrom(this.userResourceService.getCallCenterUsers(countryId, query));
  }

  fireCallCenterUser(userId: number, countryId: number): Promise<void> {
    return firstValueFrom(this.userResourceService.fireCallCenterUser(userId, countryId));
  }

  saveCallCenterUser(request: SaveCallCenterUserRequest): Promise<UserProfileModel> {
    return firstValueFrom(this.userResourceService.saveCallCenterUser(request));
  }

  getMembershipCountries(userId: number): Promise<CountryModel[]> {
    return firstValueFrom(this.userResourceService.getMembershipCountries(userId));
  }

  getEmployeeDiscount(userId: number, storeId: number): Promise<EmployeeDiscountResponse> {
    return firstValueFrom(this.userResourceService.getEmployeeDiscount(userId, storeId));
  }

  saveEmployeeDiscount(userId: number, request: SaveEmployeeDiscountRequest): Promise<void> {
    return firstValueFrom(this.userResourceService.saveEmployeeDiscount(userId, request));
  }

  removeEmployeeDiscount(userId: number, request: SaveEmployeeDiscountRequest): Promise<void> {
    return firstValueFrom(this.userResourceService.removeEmployeeDiscount(userId, request));
  }
}

export interface UserSaveRequest {
  id: number;
  phone: string;
  email: string;
  firstname: string;
  lastname: string;
  patronymic: string;
  birthdate: string;
  gender: Gender;
  address: string;
  maritalStatus: MaritalStatus;
  storeId: number;
  position: string;
  iin: string;
  /*
  bio: string;
  probations: WorkPeriodModel[];
  documents: DocumentModel[];
  children: ChildrenModel[];
  memberships: MembershipModel[];
  */
}

export interface UserMembershipRequest {
  storeId: number;
  components: ComponentType[];
  employee: boolean;
}
