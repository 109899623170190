import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { FileModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxFileService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  static fileUploadUrl = '/api/vanilla/files/upload';
  static fileDownloadUrl = '/api/vanilla/files/download';

  getDownloadUrl(fileId: string): string {
    return `${HxFileService.fileDownloadUrl}/${fileId}`;
  }

  uploadFile(body: FormData, params?: {
    folder?: string,
    temporary?: boolean,
    ref?: number,
    table?: string,
    replace?: boolean,
    fileName?: string,
  }): Observable<FileModel> {
    return this.httpClient.post<FileModel>(`/api/vanilla/files/upload`, body, {params: toHttpParams(params, true)});
  }

  deleteFile(fileId: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/files/${fileId}`);
  }

  getFileById(fileId: number): Observable<FileModel> {
    return this.httpClient.get<FileModel>(`/api/vanilla/files/${fileId}`);
  }

  getFilesByRef(params: {ref: number, table: string, folder?: string}) {
    return firstValueFrom(this.httpClient.get<FileModel[]>(`/api/vanilla/files`, {params: toHttpParams(params, true)}));
  }

  getUserTemporaryFiles(ref: number, table: string): Promise<FileModel[]> {
    return firstValueFrom(this.httpClient.get<FileModel[]>(`/api/vanilla/files`, {
      params: toHttpParams({temporary: true, ref: ref, table: table}, true)
    }));
  }
}
