import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { CityModel, CityResourceService, Currency, StoreLocationHintResponse } from '../vn-api';
import { AppPropertyBasicModel, CityStoreModel, PagedList } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class HxCityService {
  constructor(
    private http: HttpClient,
    private cityResourceService: CityResourceService
  ) {
  }

  getAllCities(params?: {
    page?: number,
    limit?: number,
    includeDisabled?: boolean,
    countryId?: number,
    sort?: string[],
  }): Promise<CityModel[]> {
    const reqParams = params ?? {};
    reqParams.page = 1;
    reqParams.limit = 1000;
    return this.getCities(reqParams).then(res => res.list);
  }

  getCities(params?: {
    page?: number,
    limit?: number,
    includeDisabled?: boolean,
    countryId?: number,
    sort?: string[],
  }): Promise<PagedList<CityModel>> {
    return firstValueFrom(this.http.get<PagedList<CityModel>>(`/api/vanilla/cities`, {params: toHttpParams(params, true)}));
  }

  getCityById(cityId: number): Observable<CityModel> {
    return this.http.get<CityModel>(`/api/vanilla/cities/${cityId}`);
  }

  getCityStores(params?: {brandId?: number}): Observable<CityStoreModel[]> {
    return this.http.get<CityStoreModel[]>(`/api/vanilla/cities/stores`, {params: toHttpParams(params, true)});
  }

  saveCity(req: Partial<CityModel>) {
    return this.http.post<{ id: number }>(`/api/vanilla/cities`, req);
  }

  removeCity(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/cities/${id}`);
  }

  getPropertiesById(cityId: number): Observable<AppPropertyBasicModel[]> {
    return this.http.get<AppPropertyBasicModel[]>(`/api/vanilla/cities/${cityId}/properties`);
  }

  getPropertyByKey(cityId: number, key: string): Observable<AppPropertyBasicModel> {
    return this.http.get<AppPropertyBasicModel>(`/api/vanilla/cities/${cityId}/properties?key=${key}`);
  }

  saveProperty(cityId: number, req: { enabled: boolean; key: string; value: string; }): Promise<{ id: number }> {
    return firstValueFrom(this.http.post<{ id: number }>(`/api/vanilla/cities/${cityId}/properties`, req));
  }

  removeProperty(cityId: number, id: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/cities/${cityId}/properties/${id}`);
  }

  getMyCities(params?: { countryId?: number }): Observable<CityModel[]> {
    return this.http.get<CityModel[]>(`/api/vanilla/cities/my`, {params: toHttpParams(params, true)});
  }

  getCurrencyByCityId(cityId: number, params: {brandId: number}): Promise<Currency> {
    return firstValueFrom(this.http.get<{result: Currency}>(`/api/vanilla/cities/${cityId}/currency`, {params: toHttpParams(params, true)})).then(res => res.result);
  }

  getStoreLocationHints(cityId: number, brandId: number): Promise<StoreLocationHintResponse> {
    return firstValueFrom(this.cityResourceService.getStoreLocationHints(cityId, brandId));
  }
}
