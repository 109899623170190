import { HttpParams } from '@angular/common/http';
import { ParamMap, Params } from '@angular/router';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { format, parseISO } from 'date-fns';
import { DateRange, toSnakeCase } from '../interface';
import { Observable, OperatorFunction, pipe, UnaryFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function toHttpParams(params?: any, snakeCase = false): HttpParams {
  let httpParams = new HttpParams();
  if (!params) {
    return httpParams;
  }
  Object.entries(params).forEach(([key, value], ix) => {
    if (value !== null && value !== undefined) {
      const queryParamName = snakeCase ? toSnakeCase(key) : key;
      if (Array.isArray(value)) {
        if (value.length !== 0) {
          httpParams = httpParams.set(queryParamName, value.join(','));
        }
      } else if (typeof value === 'object') {
        if (typeof (value as any).from === 'string' && typeof (value as any).to === 'string') {
          const dr = value as Required<DateRange>;
          if (snakeCase) {
            httpParams = httpParams.set(queryParamName + '_from', dr.from);
            httpParams = httpParams.set(queryParamName + '_to', dr.to);
          } else {
            httpParams = httpParams.set(queryParamName + 'From', dr.from);
            httpParams = httpParams.set(queryParamName + 'To', dr.to);
          }
        }
      } else {
        const strValue = String(value);
        if (strValue && strValue.trim() !== '') {
          httpParams = httpParams.set(queryParamName, String(value));
        }
      }
    }
  });
  return httpParams;
}

export function toRouterQueryParams(qp?: any): Params {
  const params: Params = {};
  Object.entries(qp).forEach(([key, value], ix) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        params[key] = value.length !== 0 ? value.join(',') : undefined;
      } else if (typeof value === 'object') {
        if (typeof (value as any).from === 'string' && typeof (value as any).to === 'string') {
          const dr = value as DateRange;
          params[key + 'From'] = dr.from;
          params[key + 'To'] = dr.to;
        }
      } else {
        const strVal = String(value);
        if (strVal && strVal.trim() !== '') {
          params[key] = strVal;
        } else {
          params[key] = undefined;
        }
      }
    }
  });
  return params;
}

export function extractDateRange(name: string, paramMap: ParamMap): DateRange | undefined {
  const from = paramMap.get(name + 'From');
  const to = paramMap.get(name + 'To');
  if (from && to) {
    return {from: from, to: to};
  }
  return undefined;
}

export function extractDefaultStoreId(): number | undefined {
  const storeId = localStorage.getItem('defaultStoreId');
  return storeId ? Number(storeId) : undefined;
}

export function extractDefaultStoreIds(): number[] | [] {
  return localStorage['multipleDefaultStoreIds'] ? JSON.parse(localStorage['multipleDefaultStoreIds']) as number[] : [];
}

export function extractNumber(name: string, paramMap: ParamMap): number | undefined {
  const numberStr = paramMap.get(name);
  if (numberStr) {
    return Number(numberStr);
  }
  return undefined;
}

export function extractNumberArr(name: string, paramMap: ParamMap): number[] {
  return paramMap.get(name)?.split(',').map(s => Number(s)) ?? [];
}

export function getFormErrors(form: AbstractControl | null): ValidationErrors | null {
  if (form === null) {
    return null;
  }
  if (form instanceof UntypedFormControl) {
    // Return FormControl errors or null
    return form.errors;
  }
  if (form instanceof UntypedFormGroup) {
    const groupErrors = form.errors;
    // Form group can contain errors itself, in that case add'em
    const formErrors: ValidationErrors = groupErrors ?? {};
    Object.keys(form.controls).forEach(key => {
      // Recursive call of the FormGroup fields
      const error = getFormErrors(form.get(key));
      if (error !== null) {
        // Only add error if not null
        formErrors[key] = error;
      }
    });
    // Return FormGroup errors or null
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }
  return null;
}

export function groupBy<T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q): Map<Q, T[]> {
  return array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());
}

export function toMap<T, Q>(arr: T[], predicate: (value: T) => Q) {
  return new Map<Q, T>(arr.map(i => [predicate(i), i]));
}

export function flattenObject(obj: any, delimeter = '.', res: any = {}, parent?: any) {
  for (let key in obj) {
    let propName = parent ? parent + delimeter + key : key;
    if (typeof obj[key] == 'object') {
      flattenObject(obj[key], delimeter, res, propName);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

export function datelc(date: string): string {
  return format(parseISO(date), 'yyyy-MM-dd');
}

export function filterNullish<T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
  return pipe(
    filter(x => x !== null && x !== undefined) as OperatorFunction<T | null |  undefined, T>
  );
}
