<nz-layout>
  <nz-sider class="menu-sidebar" [nzTheme]="theme" nzCollapsible nzWidth="256px" [nzCollapsedWidth]="0" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed">
    <div class="sidebar-logo">
      <a href="https://profile.ng.happytech.app/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
        <h1>{{'app.name' | transloco}}</h1>
      </a>
    </div>
    <ul nz-menu [nzTheme]="theme" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item routerLink="profile" nzMatchRouter>
        <i nz-icon nzType="file"></i>
        <span>{{ 'app.profile' | transloco }}</span>
      </li>
      <li nz-menu-item routerLink="notifications" nzMatchRouter>
        <i nz-icon nzType="notification" nzTheme="outline"></i>
        <span>{{ 'app.message' | transloco }}</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout class="main-layout" [ngClass]="{'main-layout__collapsed': isCollapsed}">
    <nz-header>
      <div class="app-header" nz-row nzJustify="space-between">
        <span nz-col [nzSm]="1" class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </span>
        <span nz-col [nzSm]="5">
          <nz-tag *ngIf="!isProd" [nzColor]="'volcano'">{{ 'app.test' | transloco }}</nz-tag>
        </span>
        <span nz-col *ngIf="hasAppUpdate" nz-tooltip nzTooltipTitle="Нажмите для обновления приложения" [nzSm]="1" class="header-trigger" (click)="updateApp()">
          <i class="cp__update-app" nz-icon nzType="thunderbolt" nzTheme="twotone"></i>
        </span>
        <span nz-col class="header-trigger">
          <nz-button-group>
            <a nz-button href="#">{{auth.user.firstname}}</a>
            <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
              <i nz-icon nzType="user"></i>
            </button>
          </nz-button-group>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <a (click)="checkForUpdate()">{{ 'app.upDate' | transloco }}</a>
              </li>
              <li nz-menu-item>
                <a (click)="aboutApp()">{{ 'app.app' | transloco }}</a>
              </li>
              <li nz-menu-item>
                <a (click)="auth.logout()">
                  <i nz-icon nzType="logout"></i> {{ 'app.output' | transloco }}
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </span>
      </div>
    </nz-header>
    <nz-content class="hx-content">
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
