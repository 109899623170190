export const ru = {
  'enum': {
    'CompositeProductType': {
      'SINGLE': 'Обычный',
      'COMBO': 'Комбо'
    },
    'PermissionKey': {
      'call_record_download': 'Прослушивание звонков',
      'discard_product_input': 'Массовое списывание баланса',
      'read_money_flow_report': 'Просмотр отчета Движение денег',
      'price_upsert_permission': 'Добавление/Редактирование цен'
    },
    'ProductInfoType': {
      'SERVICE': 'Услуга',
      'DELIVERY': 'Доставка',
      'PROPERTY': 'Атрибут',
      'PRODUCT': 'Продукт',
      'INGREDIENT': 'Ингредиент',
      'MATERIAL_ASSETS': 'Материальные ценности',
      'NON_MATERIAL_ASSETS': 'Нематериальные ценности'
    },
    'PaymentType': {
      'CASH': 'Наличные',
      'POS': 'POS терминал',
      'EPAY': 'ePay',
      'SENIM': 'Senim',
      'RAHMET': 'Rahmet',
      'CHECKING_ACCOUNT': 'Расчетный счет',
      'KASPI': 'Kaspi',
      'KASPI_POS': 'Kaspi POS',
      'PAYBOX': 'Paybox',
      'UZ_PAYBOX': 'Paybox VM',
      'DEBT': 'Долг',
      'FORTE': 'Forte',
      'HALYK': 'Halyk'
    },
    'Currency': {
      'KZT': 'KZT',
      'RUB': 'RUB',
      'USD': 'USD',
      'KGS': 'KGS',
      'EUR': 'EUR',
      'AZN': 'AZN',
      'UZS': 'UZS',
    },
    'KindType': {
      'REGULAR': 'Обычный',
      'DEFAULT': 'По умолчанию',
      'OPTIONAL': 'Опциональный'
    },
    'ProductCategoryType': {
      'DECORATION': 'оформление',
      'SERVICE': 'услуги',
      'FAVOURITE': 'избранные',
      'STANDART': 'стандартные',
      'NEW_FORMAT': 'нового формата'
    },
    'ComponentType': {
      'cc': 'cc',
      'cb': 'cb',
      'manager': 'manager',
      'adm': 'adm',
      'cf': 'cf',
      'kiosk': 'kiosk',
      'profile': 'profile',
      'store': 'store',
      'delivery': 'delivery'
    },
    'RecipeType': {
      'INPUT_PRODUCT': 'Вход',
      'OUTPUT_PRODUCT': 'Выход'
    }
  }
};
