import { Directive, OnDestroy } from '@angular/core';
import { PagedList } from 'hx-services';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Directive()
export abstract class HxPagedListDirective<T> implements OnDestroy {
  protected total = 0;
  protected limit = 10;
  protected page = 1;
  protected list: T[] = [];
  protected isTableLoading = false;
  protected columnSortOrderMap = new Map<string, string | null>();
  private columnSortOrderArr: string[] = [];
  protected $destroyed = new Subject<void>();
  private isInitialLoading = true;

  constructor(
    protected router: Router,
    protected aRoute: ActivatedRoute,
  ) {
  }

  load(paramMap: ParamMap) {
    this.columnSortOrderArr = [];
    this.columnSortOrderMap = new Map<string, string | null>();
    if (paramMap.has('limit')) {
      this.limit = Number(paramMap.get('limit'));
    }
    if (paramMap.has('page')) {
      this.page = Number(paramMap.get('page'));
    }
    if (paramMap.has('sort')) {
      const sortStr = paramMap.get('sort');
      if (sortStr) {
        sortStr.split(',').forEach(sortPair => {
          const [column, sortOrder] = sortPair.split(':');
          this.columnSortOrderArr.push(column);
          this.columnSortOrderMap.set(column, sortOrder === 'asc' ? 'ascend' : 'descend');
        });
      }
    }
    this.loadData();
  }

  async loadData() {
    this.isTableLoading = true;
    try {
      const {count, list} = await this.loadList();
      console.log('[HxPagedListComponent] loadList', list, count);
      this.total = count;
      this.list = list;
    } finally {
      this.isTableLoading = false;
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.isInitialLoading) {
      console.log('[HxPagedListComponent] skipped navigation');
      this.isInitialLoading = false;
      return;
    }
    const {pageSize, pageIndex, sort, filter} = params;
    const columnSortOrderArr = this.columnSortOrderArr.concat([]);
    const sortOrderMap = new Map<string, string>();
    sort.filter(el => {
      if (el.value) {
        return true;
      }
      const idx = columnSortOrderArr.findIndex(col => col === el.key);
      if (idx !== -1) {
        columnSortOrderArr.splice(idx, 1);
      }
      return false;
    }).forEach(el => {
      if (!columnSortOrderArr.includes(el.key)) {
        columnSortOrderArr.push(el.key);
      }
      if (!!el.value) {
        sortOrderMap.set(el.key, el.value);
      }
    });
    let sortParam = null;
    // ascend, descend
    if (columnSortOrderArr.length !== 0) {
      sortParam = columnSortOrderArr.map(column => `${column}:${sortOrderMap.get(column) === 'ascend' ? 'asc' : 'desc'}`).join(',');
    }
    const queryParams: Params = {
      page: pageIndex,
      limit: pageSize,
      sort: sortParam,
    };
    filter.forEach(ft => {
      const value = Array.isArray(ft.value) ? ft.value.join(',') : ft.value;
      queryParams[ft.key] = value || null;
    });
    console.log('[HxPagedListComponent] onQueryParamsChange', queryParams);
    this.router.navigate([], {
      relativeTo: this.aRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  protected getSortParams(): string[] {
    const arr: string[] = [];
    this.columnSortOrderMap.forEach((value, key) => {
      const operator = value === 'ascend' ? 'asc' : 'desc';
      arr.push(`${key}:${operator}`);
    });
    return arr;
  }

  protected abstract loadList(): Promise<PagedList<T>>;

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }
}
